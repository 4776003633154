import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { AUTHORITIES, STATUS } from "../utils/enums";
import { isUserAuthorized } from "../components/AuthorizationWrapper";

/**
 * Contains data for clients for a team
 * Stored as a list of clients
 * Should be updated each time a team is changed
 */
class RecTypeStore {
  clientsAccountsPerRecType = [];
  recTypes = null;
  recTypeTags = [];
  selectedRecType = null;
  recTypeTmos = [];
  recTypeColumnMap = {};
  groupTmoColumns = [];
  matchingRulesForRecType = null;
  matchingRuleDetails = [];
  draftRecType = {
    name: "Test RecType",
    description: "this is a test rectype hardcoded in the store",
    type: "Two sided",
    defaultDateFormat: "yyyy-MM-dd",
    defaultDecimal: 4,
    status: STATUS.DRAFT.status,
    columns: { Internal: [], External: [], Results: [] },
  };
  defaultSettings = [];

  constructor() {
    makeAutoObservable(this);
  }

  getRecTypes = () => {
    return toJS(this.recTypes);
  };

  getSelectedRecType = () => {
    return toJS(this.selectedRecType);
  };
  getRecTypeColumnMap = () => {
    return toJS(this.recTypeColumnMap);
  };

  getRecTypeTmos = () => {
    return toJS(this.recTypeTmos);
  };

  getGroupTmoColumns = () => {
    return toJS(this.groupTmoColumns);
  };

  getAccountById = (accountId) => {
    let accountList = [];
    this.clientsAccountsPerRecType.forEach((it) => (accountList = [...accountList, ...it.accountList]));
    const accountData = accountList.length ? accountList.find((it) => it.account.id === accountId) : null;
    return accountData ? accountData.account : null;
  };

  getClientsAccountsPerRecType = () => {
    return toJS(this.clientsAccountsPerRecType);
  };

  getAccounts = () => {
    let accountList = [];
    this.clientsAccountsPerRecType.map((it) => (accountList = [...accountList, ...it.accountList]));
    return accountList.map((accountData) => toJS(accountData.account));
  };

  getAccountsPerClient = (clientId) => {
    const accountList = this.clientsAccountsPerRecType.find((it) => it.client.id === clientId)["accountList"];
    return accountList.map((accountData) => toJS(accountData.account));
  };

  getSubAccounts = (accountId) => {
    let subAccounts = [];
    let accountList = [];
    this.clientsAccountsPerRecType.forEach((it) => (accountList = [...accountList, ...it.accountList]));
    const account = accountList.length ? accountList.find((it) => it.account.id === accountId) : null;
    if (account && account.subAccountList.length) {
      subAccounts = toJS(account.subAccountList.filter((it) => it !== null && !it.deleted));
    }
    return subAccounts;
  };

  setSelectedRecType = (selectedRecType) => {
    console.log("selected rec type", selectedRecType);
    this.selectedRecType = selectedRecType;
  };

  getDraftRecType = () => {
    return toJS(this.draftRecType);
  };

  setDraftRecTypeColumns = (columns) => {
    this.draftRecType.columns = columns;
  };

  getAllColumnsFromDraftRecType = () => {
    let columns = [];
    Object.entries(this.draftRecType.columns).forEach((keyValue) => {
      columns = columns.concat(keyValue[1]);
    });
    return columns;
  };

  getDefaultSettings = () => {
    return toJS(this.defaultSettings);
  };

  getMatchingRulesForRecType = () => {
    return toJS(this.matchingRulesForRecType);
  };

  getMatchingRuleDetails = () => {
    return toJS(this.matchingRuleDetails);
  };

  fetchDefaultSettings = async (teamId) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([
          AUTHORITIES.RECS_REC_VIEW,
          AUTHORITIES.RECS_UPLOADED_FILE_VIEW,
          AUTHORITIES.RECS_REC_TYPE_CONFIG_VIEW,
          AUTHORITIES.RECS_ACCOUNT_VIEW,
          AUTHORITIES.RECS_SUB_ACCOUNT_VIEW,
          AUTHORITIES.RECS_GLOBAL_MAPPING_VIEW,
        ]),
      })
    ) {
      console.log("fetchDefaultSettings: unauthorised");
      return [];
    }
    let settings = [];
    const resp = await fusionRecsApi.recType.getDefaultSettings(teamId);
    if (resp.status === 200) {
      this.defaultSettings = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return settings;
  };

  updateSetting = async (teamId, recTypeId, version, body) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_AUTOCOMPLETE_ENABLE, AUTHORITIES.RECS_REC_TYPE_REC_APPROVAL_ENABLE]),
      })
    ) {
      console.log("updateSetting: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.updateSetting(teamId, recTypeId, version, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getRecTypeTags = () => {
    return toJS(this.recTypeTags);
  };

  fetchRecTypesForTeam = async (teamId) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([
          AUTHORITIES.RECS_REC_VIEW,
          AUTHORITIES.RECS_UPLOADED_FILE_VIEW,
          AUTHORITIES.RECS_REC_TYPE_CONFIG_VIEW,
          AUTHORITIES.RECS_ACCOUNT_VIEW,
          AUTHORITIES.RECS_SUB_ACCOUNT_VIEW,
          AUTHORITIES.RECS_GLOBAL_MAPPING_VIEW,
        ]),
      })
    ) {
      console.log("fetchRecTypesForTeam: unauthorised");
      return [];
    }
    const resp = await fusionRecsApi.recType.fetchRecTypes(teamId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
      return;
    }
    this.recTypes = resp.data.filter((it) => it.fourEyes && it.fourEyes.status && it.fourEyes.status !== "DELETED");
    return resp.data;
  };

  createRecType = async (teamId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_TYPE_CREATE]) })) {
      console.log("createRecType: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.createRecType(teamId, body);
    if (resp.status === 200) {
      uiStore.addNotification("success", "Rec type successfully created");
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchTmosForRecType = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW, AUTHORITIES.RECS_ACCOUNT_VIEW]) })) {
      console.log("fetchTmosForRecType: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.getTmosForRecType(teamId, recTypeId);
    if (resp.status === 200) {
      resp.data.forEach(async (tmo) => {
        await this.fetchColumnsForTmo(teamId, tmo.id);
      });
      this.recTypeTmos = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    this.fetchGroupTmoColumns(teamId, recTypeId);
    return resp;
  };

  fetchColumnsForTmo = async (teamId, tmoId) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([
          AUTHORITIES.RECS_REC_VIEW,
          AUTHORITIES.RECS_ACCOUNT_VIEW,
          AUTHORITIES.RECS_REC_TYPE_CONFIG_VIEW,
          AUTHORITIES.RECS_MATCHING_RULE_VIEW,
        ]),
      })
    ) {
      console.log("fetchColumnsForTmo: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.getColumnsForTmo(teamId, tmoId);
    if (resp.status === 200) {
      this.recTypeColumnMap[tmoId] = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchGroupTmoColumns = async (teamId, recTypeId) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([
          AUTHORITIES.RECS_REC_VIEW,
          AUTHORITIES.RECS_ACCOUNT_VIEW,
          AUTHORITIES.RECS_REC_TYPE_CONFIG_VIEW,
          AUTHORITIES.RECS_MATCHING_RULE_VIEW,
        ]),
      })
    ) {
      console.log("fetchGroupTmoColumns: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.getGroupTmoColumns(teamId, recTypeId);
    if (resp.status === 200) {
      this.groupTmoColumns = resp.data.columns.map((col) => {
        return { ...col, columnType: col.produces, displayName: col.name };
      });
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  enableAccountForRecType = async (teamId, recTypeId, accountId, version, accountVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_REC_TYPE_ENABLE]) })) {
      console.log("enableAccountForRecType: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.enableAccountForRecType(teamId, recTypeId, accountId, version, accountVersion);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  disableAccountForRecType = async (teamId, recTypeId, accountId, version, accountVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_REC_TYPE_DISABLE]) })) {
      console.log("disableAccountForRecType: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.disableAccountForRecType(teamId, recTypeId, accountId, version, accountVersion);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  createAlias = async (teamId, recTypeId, body) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([AUTHORITIES.RECS_FUND_IDENTIFIER_ADD, AUTHORITIES.RECS_FUND_ALIAS_ADD, AUTHORITIES.RECS_SUB_ACCOUNT_CODE_ADD]),
      })
    ) {
      console.log("createAlias: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.createAlias(teamId, recTypeId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  deleteAlias = async (teamId, recTypeId, aliasId, tableType) => {
    if (
      !isUserAuthorized({
        teamId: teamId,
        oneRequired: rolesStore.getActions([
          AUTHORITIES.RECS_FUND_IDENTIFIER_REMOVE,
          AUTHORITIES.RECS_FUND_ALIAS_REMOVE,
          AUTHORITIES.RECS_SUB_ACCOUNT_CODE_REMOVE,
        ]),
      })
    ) {
      console.log("deleteAlias: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.deleteAlias(teamId, recTypeId, aliasId, tableType);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  enableSubAccount = async (teamId, recTypeId, accountId, subAccountId, recTypeVersion, accountVersion, subAccountVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_SUB_ACCOUNT_ENABLE]) })) {
      console.log("enableSubAccount: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.enableSubAccount(teamId, recTypeId, accountId, subAccountId, recTypeVersion, accountVersion, subAccountVersion);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  disableSubAccount = async (teamId, recTypeId, accountId, subAccountId, recTypeVersion, accountVersion, subAccountVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_SUB_ACCOUNT_REMOVE]) })) {
      console.log("disableSubAccount: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.disableSubAccount(teamId, recTypeId, accountId, subAccountId, recTypeVersion, accountVersion, subAccountVersion);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchMatchingRules = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_VIEW]) })) {
      console.log("fetchMatchingRules: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.fetchMatchingRules(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.matchingRulesForRecType = resp.data;
    }
    return resp;
  };

  fetchMatchingRuleDetails = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_VIEW]) })) {
      console.log("fetchMatchingRuleDetails: unauthorised");
      return { status: 401 };
    }
    this.fetchMatchingRules(teamId, recTypeId);
    const resp = await fusionRecsApi.recType.fetchMatchingRuleDetails(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.matchingRuleDetails = resp.data;
    }
    return resp;
  };

  fetchRecTypeAndSettings = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW, AUTHORITIES.RECS_GLOBAL_MAPPING_VIEW]) })) {
      console.log("fetchRecTypeAndSettings: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.fetchRecTypeAndSettings(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.setSelectedRecType(resp.data);
      const tagsResp = await fusionRecsApi.recType.getTagsForRecType(teamId, recTypeId);
      if (tagsResp.status === 200) {
        this.recTypeTags = tagsResp.data;
      } else {
        uiStore.errorNotification(resp.data);
      }
    }
    return resp;
  };

  fetchClientsAccountsPerRecType = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW, AUTHORITIES.RECS_GLOBAL_MAPPING_VIEW]) })) {
      console.log("fetchClientsAccountsPerRecType: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.fetchClientsAccountsPerRecType(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.clientsAccountsPerRecType = resp.data;
    }
    return resp;
  };

  copyMatchingRules = async (teamId, recTypeId, accountId, subAccountId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_ACCOUNT_ENABLE]) })) {
      console.log("copyMatchingRules: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.copyMatchingRules(teamId, recTypeId, accountId, subAccountId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      uiStore.addNotification("success", "Matching rules applied");
    }
    return resp;
  };

  updateRestartRec = async (teamId, rectypeId, accountId, subAccountId, restartSetting) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_MATCHING_RULE_ACCOUNT_ENABLE]) })) {
      console.log("updateRestartRec: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.updateRestartRec(teamId, rectypeId, accountId, subAccountId, restartSetting);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getWorkflowsAndTemplatesForTmo = async (teamId, tmoId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getWorkflowsAndTemplatesForTmo: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.recType.getWorkflowsAndTemplatesForTmo(teamId, tmoId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  getRowGroupSchema = async (teamId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_REC_VIEW]) })) {
      console.log("getRowGroupSchema: unauthorised");
      return { status: 401 };
    }
    let data = [];
    const resp = await fusionRecsApi.recType.getRowGroupSchema(teamId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      data = resp.data;
    }
    return data;
  };

  updateVersion = (id, version) => {
    this.recTypes = this.recTypes.map((recType) => {
      if (recType.id === id && recType.version >= 0) {
        recType = { ...recType, version: version };
      }
      return recType;
    });
  };
}

export { RecTypeStore };
