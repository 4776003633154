import { makeAutoObservable, toJS } from "mobx";
import { fusionRecsApi } from "../api";
import { uiStore, rolesStore } from "./Store";
import { isUserAuthorized } from "../components/AuthorizationWrapper";
import { AUTHORITIES } from "../utils/enums";

/**
 * Contains data for clients and accounts for a team
 * Stored as an object of (key) client ids to (value) list of corresponding accounts
 * Should be updated each time a team is changed
 */
class AccountStore {
  accountsPerTeam = null;
  defaultAccountSettings = [];
  selectedAccount = null;

  constructor() {
    makeAutoObservable(this);
  }

  getDefaultAccountSettings = () => {
    return toJS(this.defaultAccountSettings);
  };

  setDefaultAccountSettings = (defaultAccountSettings) => {
    this.defaultAccountSettings = defaultAccountSettings;
  };

  fetchAccountsForTeam = async (teamId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW, AUTHORITIES.RECS_GLOBAL_MAPPING_VIEW]) })) {
      console.log("fetchAccountsForTeam: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.account.fetchAccountsForTeam(teamId);
    if (resp.status === 200) {
      result = resp.data;
      this.setAccountsForSelectedTeam(result);
    }
    return result;
  };

  getAccountsForSelectedTeam = () => {
    return toJS(this.accountsPerTeam);
  };

  setAccountsForSelectedTeam = (accountsPerTeam) => {
    this.accountsPerTeam = accountsPerTeam;
  };

  fetchDefaultSettings = async (teamId) => {
    if (!isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW]) })) {
      console.log("fetchDefaultSettings: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.account.fetchDefaultAccountSettings(teamId);
    if (resp.status === 200) {
      result = resp.data;
      this.setDefaultAccountSettings(result);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  createAccount = async (teamId, clientId, reqBody) => {
    if (!isUserAuthorized({ teamId: teamId, allRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_CREATE]) })) {
      console.log("createAccount: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.account.createAccount(teamId, clientId, reqBody);
    if (resp.status === 200) {
      this.selectedAccount = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setSelectedAccount = (account) => {
    this.selectedAccount = account;
  };

  getSelectedAccount = () => {
    return toJS(this.selectedAccount);
  };

  saveAccountResponse = (resp) => {
    this.selectedAccount = { ...resp.data.account, clientId: resp.data.clientId, clientName: resp.data.clientName };
  };

  removeSelectedAccount = () => {
    this.selectedAccount = null;
  };

  updateAccount = async (teamId, clientId, accountId, version, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("updateAccount: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.account.updateAccount(teamId, clientId, accountId, version, body);
    if (resp.status === 200) {
      this.saveAccountResponse(resp);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  updateSetting = async (teamId, clientId, accountId, version, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("updateSetting: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.account.updateSetting(teamId, clientId, accountId, version, body);
    if (resp.status === 200) {
      this.saveAccountResponse(resp);
    } else {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setClient = async (teamId, clientId, accountId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("setClient: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.account.setClient(teamId, clientId, accountId, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  updateCurrency = async (teamId, clientId, accountId, currencyId, version) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("updateCurrency: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.account.updateCurrency(teamId, clientId, accountId, currencyId, version);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  fetchAccountById = async (teamId, accountId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW, AUTHORITIES.RECS_MATCHING_RULE_VIEW]) })) {
      console.log("fetchAccountById: unauthorised");
      return { status: 401 };
    }
    const resp = await fusionRecsApi.account.fetchAccountById(teamId, accountId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    } else {
      this.saveAccountResponse(resp);
    }
    return resp;
  };

  fetchEnabledRecTypesForAccount = async (teamId, accountId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW]) })) {
      console.log("fetchEnabledRecTypesForAccount: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.account.fetchEnabledRecTypesForAccount(teamId, accountId);
    if (resp.status === 200) {
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  fetchRecTypesSubAccountsAliases = async (teamId, accountId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW, AUTHORITIES.RECS_MATCHING_RULE_VIEW]) })) {
      console.log("fetchRecTypesSubAccountsAliases: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.account.fetchRecTypesSubAccountsAliases(teamId, accountId);
    if (resp.status === 200) {
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  fetchRecTypesWithAliases = async (teamId, accountId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW]) })) {
      console.log("fetchRecTypesWithAliases: unauthorised");
      return [];
    }
    let result = [];
    const resp = await fusionRecsApi.account.fetchRecTypesWithAliases(teamId, accountId);
    if (resp.status === 200) {
      result = resp.data;
    } else {
      uiStore.errorNotification(resp.data);
    }
    return result;
  };

  setAutoCompleteRule = async (teamId, accountId, acId, version, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("setAutoCompleteRule: unauthorised");
      return [];
    }
    const resp = await fusionRecsApi.account.setAutoCompleteRule(teamId, accountId, acId, version, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setAutoCompleteRuleSchedule = async (teamId, accountId, acId, version, recTypeId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("setAutoCompleteRuleSchedule: unauthorised");
      return [];
    }
    const resp = await fusionRecsApi.account.setAutoCompleteRuleSchedule(teamId, accountId, acId, version, recTypeId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setRecStartDateForAutocomplete = async (teamId, accountId, acId, version, recTypeId, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("setRecStartDateForAutocomplete: unauthorised");
      return {};
    }
    const resp = await fusionRecsApi.account.setRecStartDateForAutocomplete(teamId, accountId, acId, version, recTypeId, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  disableAutoCompleteRule = async (teamId, accountId, version, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("disableAutoCompleteRule: unauthorised");
      return [];
    }
    const resp = await fusionRecsApi.account.disableAutoCompleteRule(teamId, accountId, version, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  findRecTypeAccountSettings = async (teamId, accountId, recTypeId) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_VIEW]) })) {
      console.log("findRecTypeAccountSettings: unauthorised");
      return {};
    }
    const resp = await fusionRecsApi.account.findRecTypeAccountSettings(teamId, accountId, recTypeId);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };

  setEnableReplaceFiles = async (teamId, accountId, recTypeId, version, body) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_EDIT]) })) {
      console.log("setEnableReplaceFiles: unauthorised");
      return {};
    }
    const resp = await fusionRecsApi.account.setEnableReplaceFiles(teamId, accountId, recTypeId, version, body);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };


  deleteAccount = async (teamId, clientId, accountId, accountVersion) => {
    if (!isUserAuthorized({ teamId: teamId, oneRequired: rolesStore.getActions([AUTHORITIES.RECS_ACCOUNT_DELETE]) })) {
      console.log("deleteAccount: unauthorised");
      return {};
    }
    const resp = await fusionRecsApi.account.delete(teamId, clientId, accountId, accountVersion);
    if (resp.status !== 200) {
      uiStore.errorNotification(resp.data);
    }
    return resp;
  };


  updateSelectedAccountVersion = (version) => {
    if (this.selectedAccount && this.selectedAccount.version >= 0) {
      this.selectedAccount.version = version;
    }
  };
}

export { AccountStore };
